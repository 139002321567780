#scrollToTopBtn {
  font-size: 30px;
}

@media (max-width: 767px) {
  #scrollToTopBtn {
    width: 50px;
    height: 25px;
    font-size: 20px;
    bottom: 0;
    left: 4px;
  }
}
