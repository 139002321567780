/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure full width and prevent horizontal scrolling */
html, body {
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

img, video, iframe {
  max-width: 100%;
  height: auto;
}

@media (max-width: 430px) {
  html {
      overflow-x: hidden; /* Prevent horizontal scrolling */
      overflow-y: visible; /* Allow vertical scrolling */
      scroll-padding-top: 10px;
  }
  
}