@import "views/home.css";
@import "views/notFound.css";
@import "layout/navBar.css";
@import "global/base.css";
@import "components/parallax.css";
@import "components/contentExperience.css";
@import "components/contentEducation.css";
@import "components/contentSkills.css";
@import "components/contentProjects.css";
@import "components/timeline.css";
@import "components/scrollToTopBtn.css";
@import "components/contentIntro.css";
@import "components/profilepicture.css";
@import "components/navbar.css";

@tailwind base;
@tailwind components;
@tailwind utilities;