.photo {
  flex: 1; /* Takes up equal space as .description */
  text-align: center;
}

#profileImg {
  width: 25rem;
  height: 30rem;
}

.description {
  flex: 1; /* Takes up equal space as .photo */
  padding-left: 20px;
  max-width: 80%;
}

.description h2 {
  margin-bottom: 10px;
}

.description p {
  line-height: 1.6; /* Improves readability of text */
}

#introDescription {
  font-size: 1.35rem;
}

.content-wrapper {
  display: flex; 
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto; /* Centers horizontally */
  padding: 45px; /* Adds spacing inside the container */
  background-color: rgba(255, 255, 255, 0.8); 
  height: 600px;
  text-align: center; /* Centers text or inline content */
}

/* Desktop Viewports Start */
@media (min-width: 767px) and (max-height: 1440px) {
  #introName {
    font-size: 4rem;
  }

  #introDescription {
    font-size: 1.5rem;
  }

  #profileImg {
    width: 25rem;
    height: 32rem;
  }

  .content-wrapper {
    width: 80rem;
    height: 40rem;
  }
}

/* 4k UHD */
@media (min-width: 2560px) and (min-height: 1440px) {
  #introName {
    font-size: 5rem;
  }

  #introDescription {
    font-size: 2rem;
  }

  #profileImg {
    width: 45rem;
    height: 45rem;
  }

  .content-wrapper {
    width: 2000px;
    height: 770px;
  }
}
/* Desktop Viewports End */

/* Mobile Viewports Start */
@media (max-width: 767px) {
  #introDescription {
    display: none;
  }

  #description {
    display: none;
  }

  #profileImg {
    width: 17rem;
    height: 20rem;
    border-radius: 0;
  }

  #profilePhoto {
    position: absolute;
    bottom: 0px;
  }

  .content-wrapper {
    display: flex; 
    justify-content: center;
    align-items: center;
    width: 1295px;
    padding: 20px;
    padding-bottom: 1rem;
    background-color: rgba(255, 255, 255, 0.2);
    height: 100%;
    width: 100%;
  }
}

@media (min-height: 600px) and (max-height: 800px) and (max-width: 414px) {
  #profileImg {
    width: 15rem;
    height: 15rem;
  }
}

@media (min-height: 801px) and (max-height: 915px) and (max-width: 414px) {
  #profileImg {
    width: 19rem;
    height: 19rem;
  }
}
/* Mobile Viewports End */
