.not-found {
    text-align: center;
    margin-top: 50px;
    font-family: Arial, sans-serif;
  }
  
  .not-found h1 {
    font-size: 72px;
    color: #ff0000;
  }
  
  .not-found p {
    font-size: 24px;
    color: #333;
  }
