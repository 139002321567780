#parallax {
  min-height: 50vh;
}

@media (max-height: 1440px) and (min-width: 767px) {
  #parallax {
    background-position: center top; /* Adjust position for shorter screens */
    min-height: 90vh; 
  }
}

@media (max-width: 767px) {
  #parallax {
    min-height: 40vh;
    background-attachment: scroll;
  }
}
