#contentSkills {
  background-color: #f0f4ff;
}

#skillsBar {
  width: 40%;
}

@media (max-width: 767px) {
  #skillsBar {
    width: 80%;
  }
}
