#contentProjects {
  background: linear-gradient(to right, #eaf3fc, #ffffff);
}

.projectCardImg {
  opacity: 1;
  transition: opacity 0.3s;
}

.projectCardImg:hover {
  opacity: 0.9;
}
