/* Timeline Container */
.timeline {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

/* Vertical Line in the Center */
.timeline-line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  background-color: #000;
  transform: translateX(-50%);
}

.timeline .timeline-line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  background-color: #e5e7eb;
}

/* Timeline Item Layout */
.timeline-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.timeline-item:nth-child(1) .timeline-dot {
  animation-delay: 0.2s;
}
.timeline-item:nth-child(2) .timeline-dot {
  animation-delay: 0.4s;
}
.timeline-item:nth-child(3) .timeline-dot {
  animation-delay: 0.6s;
}

.timeline-content-left {
  flex: 1;
  text-align: right;
}

/* Timeline Dot */
.timeline-dot {
  width: 10px;
  height: 10px;
  background-color: #3b82f6;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 5px);
  opacity: 0;
  transform: translateY(10px);
}

.timeline-content-right {
  flex: 2;
  margin-left: calc(50% + 20px);
  width: 33vw;
}

@keyframes moveUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes moveUpAndFade {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.timeline-dot.animate-dot {
  animation: moveUpAndFade 0.8s ease-in-out forwards;
}

.establishmentName {
  font-size: 1.2rem;
  font-weight: bold;
}

.yearText {
  font-size: 1.2rem;
  font-weight: bold;
}

/* Slide Animation */
@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.timeline-content-left.animate-content-left {
  animation: slideInFromLeft 1s ease-out forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.timeline-content-right.animate-content-right {
  animation: slideInFromRight 1s ease-out forwards;
}

@media (max-width: 767px) {
  .timeline-content-right {
    flex: 2;
    margin-left: calc(50% + -115px);
    width: 40vw;
    font-size: 11px;
    text-align: left;
  }

  .timeline-content-left {
    flex: 1;
    text-align: right;
    padding-left: 70px;
    font-size: 12px;
  }

  .yearText {
    font-size: 15px;
  }

  .infoText {
    font-size: 10px;
  }
  .establishmentName {
    font-size: 10px;
  }

  .divider {
    height: 0rem !important;
  }

  .hero-content {
    min-width: 396px;
  }

  .establishmentDescription {
    text-align: left !important;
  }
}
